<template>
  <div>
    <div class="prompt-text">
      秉承“客户为先，诚信至上”的宗旨、“让生活变得简单，快乐！”为我们的最终目标，为广大用户提供最优质的商品及服务。开放平台欢迎国际国内知名品牌、拥有特色货品及垂直电商入驻。
    </div>
    <div class="big-title">一.网店入驻流程：</div>
    <div class="table-process">
      <div class="tr th">
        <div class="td">
          准备材料
          <i class="iconfont head-icon">&#xe664;</i>
        </div>
        <div class="td">
          提交入驻
          <i class="iconfont head-icon">&#xe664;</i>
        </div>
        <div class="td">
          入驻审核
          <i class="iconfont head-icon">&#xe664;</i>
        </div>
        <div class="td">
          开店任务
          <i class="iconfont head-icon">&#xe664;</i>
        </div>
        <div class="td">上线经营</div>
      </div>
      <div class="tr">
        <div class="td">准备材料</div>
        <div class="td">提交公司信息</div>
        <div class="td">资质初审</div>
        <div class="td">维护信息地址</div>
        <div class="td">发布商品信息</div>
      </div>
      <div class="tr">
        <div class="td">准备经营资质</div>
        <div class="td">提交经营资质</div>
        <div class="td">招商复审</div>
        <div class="td">开通结算账户<i class="iconfont body-icon">&#xe688;</i></div>
        <div class="td">装修店铺页面</div>
      </div>
      <div class="tr">
        <div class="td">开通钱包<i class="iconfont body-icon">&#xe688;</i></div>
        <div class="td">确认在线协议</div>
        <div class="td">店铺授权</div>
        <div class="td">缴费、开店考试</div>
        <div class="td">上线销售</div>
      </div>
    </div>
    <div class="big-title">二.请认真查阅入驻相关说明，准备入驻资质：</div>
    <div class="ready-box">
      <div class="item-box">
        <div class="item">
          <i class="iconfont">&#xe663;</i>
          <div class="text">资质标准</div>
        </div>
        <div class="name">招商基础资质标准</div>
      </div>
      <div class="item-box">
        <div class="item">
          <i class="iconfont">&#xe660;</i>
          <div class="text">重点品牌</div>
        </div>
        <div class="name">开放平台重点招募品牌</div>
      </div>
      <div class="item-box">
        <div class="item">
          <i class="iconfont">&#xe662;</i>
          <div class="text">资费标准</div>
        </div>
        <div class="name">开放平台各类目资费一览表</div>
      </div>
      <div class="item-box">
        <div class="item">
          <i class="iconfont">&#xe661;</i>
          <div class="text">常见问题</div>
        </div>
        <div class="name">入驻审核常见问题</div>
      </div>
    </div>
    <div class="big-title">三.请再次确认您是否已准备好相应的资质</div>
    <el-checkbox-group class="check-box" v-model="checkList1" @change="changeCheckbox">
      <el-checkbox label="1">我已准备好公司营业执照、法人等相关信息及资质电子图片</el-checkbox>
<!--      <el-checkbox label="2">我已准备好经营品牌授权、行业及产品资质信息及资质电子图片</el-checkbox>-->
<!--      <el-checkbox label="3">我已准备实名认证通过的钱包/正在提交实名认证《注册企业钱包及提交实名操作说明》</el-checkbox>-->
    </el-checkbox-group>

    <div class="btn-box">
      <el-button @click="$router.go(-1)">返回重选</el-button>
      <el-button class="btn-red" @click="nextBtn">开始提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    ruleForm: {
      type: Object,
      default: () => {}
    },
    checkList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      checkList1: this.checkList,
    }
  },
  created() {

  },
  methods: {
    changeCheckbox(val) {
      this.$emit('update:checkList', val)
    },
    nextBtn() {
      if(this.checkList1.length === 1){
        this.$emit('update:currentIndex', 2)
      } else {
        this.$message('请勾选确认您是否已准备好相应的资质')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.prompt-text {
  font-size: 12px;
  color: #666;
}
.big-title {
  font-size: 16px;
  margin: 35px 0 26px;
}
.check-box {
  .el-checkbox {
    display: block;
    margin-right: 0;
    font-weight: 400;
    color: #333;
    margin-top: 16px;
    font-size: 12px;
    &:first-child {
      margin-top: 0;
    }
  }
}
.table-process {
  margin: 0 50px;
  border-right: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  .tr {
    width: 100%;
    display: flex;
    border-top: 1px solid #E5E5E5;
    .td {
      width: 20%;
      height: 50px;
      text-align: center;
      line-height: 50px;
      border-left: 1px solid #E5E5E5;
      position: relative;
      &:nth-child(odd) {
        background: #F8F8F8;
      }
      .head-icon {
        position: absolute;
        color: #E1E1E1;
        font-size: 40px;
        right: -20px;
        z-index: 1;
      }
      .body-icon {
        color: #FFAA00;
        font-size: 12px;
        margin-left: 4px;
      }
    }
  }
}
.ready-box {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-left: -25px;
  margin-top: -10px;
  .item-box {
    margin-left: 75px;
    line-height: 1;
    margin-top: 10px;
    .item {
      background: #FD4446;
      padding: 26px 0 20px;
      color: #fff;
      width: 150px;
      .iconfont {
        font-size: 50px;
      }
      .text {
        font-size: 20px;
        margin-top: 20px;
      }
    }
    .name {
      font-size: 12px;
      color: #666;
      margin-top: 12px;
    }
  }
}
.btn-box {
  text-align: right;
  padding: 10px 74px;
  border-top: 1px solid #DCDCDC;
  margin: 26px -74px 0 -74px;
}
</style>