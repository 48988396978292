<template>
  <div>
    <div class="prompt-info">
      <div class="left">
        <i class="iconfont" style="font-size: 36px; color: #FF9450; margin-right: 20px">&#xe72a;</i>
      </div>
      <div>
        <div>1.审核时效，资质初审7个工作日，复审15个工作日，店铺授权1~3个工作日。</div>
        <div>2.你可登录查看审核状态。也可通过商务联系人邮箱了解入驻进度。</div>
        <div>3.店铺开通后，登录网店管理后台的用户名和密码会发送到您入驻时填写的联系人邮箱中。</div>
      </div>
    </div>
    <div class="big-title">联系信息 <span class="blue" style="font-size: 12px; cursor: pointer" @click="$emit('update:currentIndex', 6)">修改</span></div>
    <div class="contact-info">
      <div class="item">主体名称：{{ruleForm.corporate_name}}</div>
      <div class="item">主体证件号：{{ruleForm.legal_representative_certificates_num}}</div>
      <div class="item">联系人姓名：{{ruleForm.contact_name}}</div>
      <div class="item">联系人手机：{{ruleForm.contact_phone}}</div>
      <div class="item">联系人邮箱：{{ruleForm.contact_email}}</div>
    </div>

    <div class="big-title">入驻状态</div>
    <div class="state-info">
      <div class="left">
        <i class="iconfont">&#xe646;</i>
        已通过
      </div>
      <div class="right">
        贵公司资质信息已提交完成，会尽快安排第三方公司为您进行资质审核。
        <br>
        安装APP，可随时查询入驻审核进度！
      </div>
    </div>

    <div class="big-title">入驻状态</div>
    <el-table :data="auditList" style="width: 100%; margin-bottom: 60px" border
              :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#fafafa',fontSize: '14px',height:'50px'}"
              :cell-style="{fontSize: '14px',color: '#333',height: '60px', background:'#fff'}">
      <el-table-column prop="audit" label="入驻审核" align="center" width="236"></el-table-column>
      <el-table-column prop="update_time" label="操作时间" align="center" width="236"></el-table-column>
      <el-table-column prop="result" label="结果" align="center"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    ruleForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      auditList: [
        {
          audit: '网店提交申请',
          update_time: '',
          result: '提交资质审核'
        }
      ],
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.$http.axiosGet(this.$api.stuStoreSettingsStoreSettingMain, (res) => {
        if (res.code === 200) {
          if (Object.prototype.toString.call(res.data) === '[object Object]') {
            this.$emit('update:ruleForm', {...this.ruleForm, ...res.data})
            this.auditList[0].update_time = res.data.update_time
          }
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.big-title {
  font-size: 16px;
  margin: 30px 0 26px;
}
.blue {
  color: #2461EF;
}
.prompt-info {
  display: flex;
  align-items: center;
  background: #FFFDF0;
  border: 1px solid #FFC8A0;
  padding: 12px 46px;
  line-height: 30px;
  color: #FF9450;
}
.contact-info {
  padding: 10px 38px;
  background: #fafafa;
  border: 1px solid #E5E5E5;
}
.state-info {
  display: flex;
  align-items: center;
  border: 1px solid #E5E5E5;
  padding: 12px 46px;
  line-height: 30px;
  .left {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-right: 30px;
    .iconfont {
      color: #2DC079;
      font-size: 30px;
      margin-right: 20px;
    }
  }
}
</style>