<template>
  <div class="set-box">
    <div class="progress-box">
      <div class="progress-main-box">
        <template v-for="(item, index) in progressStep">
          <div class="item-text" v-if="item">
            <div :class="currentIndex > index ? 'sort current' : 'sort'" v-if="index === 0 || index === progressStep.length - 1">{{ index === 6 ? 2 : index + 1 }}</div>
            <div v-else :class="currentIndex > index ? 'small-sort current' : 'small-sort'"></div>
            <div :class="currentIndex > index ? 'active-text' : ''">{{ item }}</div>
          </div>
          <div class="line" v-if="index < progressStep.length - 1 && item" :class="currentIndex > index + 1 ? 'line current' : 'line'"></div>
        </template>
      </div>
    </div>
    <div class="set-content">
      <StepOne v-if="currentIndex === 1" :currentIndex.sync="currentIndex" :ruleForm.sync="ruleForm" :checkList.sync="checkList"></StepOne>
      <StepTwo v-if="currentIndex === 2" :currentIndex.sync="currentIndex" :ruleForm.sync="ruleForm"></StepTwo>
      <StepTwoOne v-if="currentIndex === 3" :currentIndex.sync="currentIndex" :ruleForm.sync="ruleForm" :fileListShenfen.sync="fileListShenfen"></StepTwoOne>
      <StepTwoTwo v-if="currentIndex === 4" :currentIndex.sync="currentIndex" :ruleForm.sync="ruleForm"></StepTwoTwo>
      <StepThree v-if="currentIndex === 5" :currentIndex.sync="currentIndex" :ruleForm.sync="ruleForm"></StepThree>
      <StepFour v-if="currentIndex === 6" :currentIndex.sync="currentIndex" :ruleForm.sync="ruleForm":checkList.sync="checkList"></StepFour>
      <StepFive v-if="currentIndex === 7" :currentIndex.sync="currentIndex" :ruleForm.sync="ruleForm"></StepFive>
    </div>
  </div>
</template>

<script>
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import StepTwoOne from "./components/StepTwoOne";
import StepThree from "./components/StepThree";
import StepFour from "./components/StepFour";
import StepFive from "./components/StepFive";
import StepTwoTwo from "./components/StepTwoTwo";

export default {
  data() {
    return {
      progressStep: ['准备资料', '主体信息', '', '', '店铺信息', '店铺命名', '提交审核'],
      currentIndex: null,
      checkList: [],
      fileListShenfen: [],
      ruleForm: {
        id: null,

        contact_name: '',
        contact_phone: '',
        contact_email: '',
        is_abutting_joint_JD: 1, // 是否对接京东招商人员 0:无 1:有
        name: '',
        recommend_code: '',

        document_type: '', // 证件类型 0:多证合一营业执照（统一社会信用代码） 1:多证合一营业执照（非统一社会信用代码）
        business_license_src: '',
        corporate_name: '',
        unified_social_credit_code: void 0,
        business_license_location: [],
        business_license_location_province: null,
        business_license_location_city: null,
        business_license_location_area: null,
        business_license_address: null,
        incorporation_date: null,
        business_date_begin: null,
        business_date_end: null,
        business_date_type: 0, // 营业日期类型 0:默认 1:长期
        registered_capital: void 0,
        business_scope: '',
        legal_representative_certificate_type: null, // 法定代表人证件类型 0:大陆身份证 1:护照 2:港澳居民通行证 3:台湾居民通行证
        // legal_person__document_electron: '',
        id_card_front_src: '', // 证件照正面文件路径
        id_card_reserve_src: '', // 证件照反面文件路径
        legal_representative_name: '',
        legal_representative_certificates_num: null,
        alidity_period_begin: null,
        alidity_period_end: null,
        alidity_period_type: null, // 有效期类型 0:默认 1:长期

        taxpayers_type: null, // 纳税人类型 0:一般纳税人 1:小规模纳税人 2非增值税纳税人
        taxpayers_identity_num: null,
        yax_type: null, // 纳税类型 0:0% 1:1% 2:3% 3:6% 4:7% 5:9% 6:10% 7:13% 8:图书9%免税
        tax_pdf_src: '',
        tax_e_src: '',
        corporate_settlement_bank_account: null,
        branch_bank_account: null,
        branch_bank_name: null,
        open_account_bank: [],
        open_account_bank_province: null,
        open_account_bank_cit: null,
        open_account_bank_area: null,

        company_type: null, // 公司类型 0:生产厂商 1:品牌商 2:代理商 3:经销商
        company_web: null,
        annual_sales: void 0,
        goods_num: null, // 商品数量 0:0-100 1:100-200 2:200-500 3:大于500
        avg_price: null, // 平均客单价 0:0-100 1:100-200 2:200-500 3:大于500
        warehouse_situation: null, // 仓库情况 0:自有仓库 1:第三方仓库 2:无仓库
        warehouse_address: null,
        common_logistics: '',
        ERP_type: null, // ERP类型 0:自有ERP 1:第三方ERP代运营 2:无
        agent_operating_company: null,

        store_type: null, // 店铺类型 0:旗舰店 1:专营店 2:专卖店
        good_category_id: [],
        company_abbreviation: '',
        category_description: '',
        shop_suffix: null, // 店铺后缀 0:专营店 1:专卖店 2:旗舰店 3:官方旗舰店
      },
    }
  },
  components: {
    StepTwoTwo,
    StepOne,
    StepTwo,
    StepTwoOne,
    StepThree,
    StepFour,
    StepFive,
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.$http.axiosGet(this.$api.stuStoreSettingsStoreSettingMain, (res) => {
        if (res.code === 200) {
          if (Object.prototype.toString.call(res.data) === '[object Object]') {
            this.checkList = ['1']
            this.currentIndex = 7
            this.$router.push({
              path: this.$route.path,
              query: {...this.$route.query, isAnswer: 1} // 0: 未答题， 1:已答题
            })
          } else {
            this.currentIndex = 1
          }
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.set-box {
  border: 1px solid #E5E5E5;
}
.progress-box {
  background: #fafafa;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-weight: 500;
  padding: 20px 74px;
  .progress-main-box {
    width: 100%;
    display: flex;
    .item-text {
      z-index: 1;
      width: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .small-sort {
      width: 100%;
      height: 32px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background: #D5D5D5;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &.current {
        &:before {
          background: #FD4446;
        }
      }
    }
    .sort {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      text-align: center;
      line-height: 32px;
      background: #D5D5D5;
      color: #fff;
      &.current {
        background: #FD4446;
      }
    }
    .active-text {
      color: #FD4446;
    }
    .line {
      //width: 1%;
      //flex: 1;
      width: calc(25% - 15px);
      height: 3px;
      background: #d9d9d9;
      margin-left: -30px;
      margin-right: -30px;
      margin-top: 14px;
      z-index: 0;
      &.current {
        background: #FD4446;
      }
    }
  }
}
.set-content {
  padding: 20px 74px 0;
}
</style>