<template>
<div>
  <div class="big-title">入驻联系人信息</div>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm" label-suffix=":">
    <el-form-item label="联系人姓名" prop="contact_name">
      <el-input v-model.trim="ruleForm.contact_name" placeholder="请输入联系人姓名" clearable style="width: 380px"></el-input>
    </el-form-item>
    <el-form-item label="联系人手机" prop="contact_phone">
      <el-input v-model.trim="ruleForm.contact_phone" placeholder="请输入联系人手机" clearable style="width: 380px"></el-input>
    </el-form-item>
    <el-form-item label="联系人电子邮箱" prop="contact_email">
      <el-input v-model.trim="ruleForm.contact_email" placeholder="请输入联系人邮箱" clearable style="width: 380px"></el-input>
      <div class="message">入驻过程中用于接收审核结果、开店账号密码信息，请务必仔细确认。</div>
      <div class="message">建议使用QQ、网易邮箱、189、自主企业邮箱可能无法正常收取邮件。</div>
    </el-form-item>
    <el-form-item label="对接招商人员" prop="is_abutting_joint_JD">
      <el-radio-group v-model="ruleForm.is_abutting_joint_JD" style="width: 380px">
        <el-radio :label="0">无</el-radio>
        <el-radio :label="1">有</el-radio>
      </el-radio-group>
      <div class="message">如果没有联系过招商人员请选择“无”。</div>
    </el-form-item>
    <el-form-item label="姓名" prop="name" v-if="ruleForm.is_abutting_joint_JD === 1">
      <el-input v-model.trim="ruleForm.name" placeholder="请输入姓名" clearable style="width: 380px"></el-input>
    </el-form-item>
    <el-form-item label="推荐码" prop="recommend_code">
      <el-input v-model.trim="ruleForm.recommend_code" placeholder="请输入推荐码" clearable style="width: 380px"></el-input>
      <div class="message">请录入推荐网店的网店ID，由数字组成；如果没有受到邀请，则不用填写。</div>
    </el-form-item>
  </el-form>

  <div class="btn-box">
    <el-button @click="$emit('update:currentIndex', 1)">上一步</el-button>
    <el-button class="btn-red" @click="submitForm('ruleForm')">下一步，完善主体信息</el-button>
  </div>
</div>
</template>

<script>
import {validateEmail, validatePhone} from "../../../admin/storeRegister/component/validator";

export default {
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    ruleForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    let validateRecommend = (rule, value, callback) => {
      if(value) {
        const reg = /^\d{1,10}$/
        if (reg.test(value)) {
          return callback();
        }
        return callback(new Error("填写限制1-10个数字"));
      }
      return callback();
    };
    let validateName = (rule, value, callback) => {
      if (this.ruleForm.is_abutting_joint_JD === 1) {
        if (!value) {
          return callback(new Error('请输入姓名'));
        }
        return callback();
      }
      return callback();
    }
    return {
      rules: {
        contact_name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        contact_phone: [
          { required: true, validator: validatePhone, trigger: ['blur', 'change'] }
        ],
        contact_email: [
          { required: true, validator: validateEmail, trigger: ['blur', 'change'] }
        ],
        // is_abutting_joint_JD: [
        //   { required: true, message: '请选择对接招商人员', trigger: 'change' },
        // ],
        name: [
          // { required: true, message: '请输入姓名', trigger: 'blur' },
          { validator: validateName, trigger: 'blur' },
        ],
        recommend_code: [
          { validator: validateRecommend, trigger: ['blur', 'change'] }
        ],
      },
    }
  },
  created() {
    if (this.$route.query.isAnswer === '1') {
      this.getInfo()
    }
  },
  methods: {
    getInfo() {
      this.$http.axiosGet(this.$api.stuStoreSettingsStepOneInfo, (res) => {
        if (res.code === 200) {
          if (Object.prototype.toString.call(res.data) === '[object Object]') {
            this.$emit('update:ruleForm', {...this.ruleForm, ...res.data})
          }
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            contact_name: this.ruleForm.contact_name,
            contact_phone: this.ruleForm.contact_phone,
            contact_email: this.ruleForm.contact_email,
            is_abutting_joint_JD: this.ruleForm.is_abutting_joint_JD,
            recommend_code: this.ruleForm.recommend_code,
          }
          if (this.ruleForm.is_abutting_joint_JD) {
            params.name = this.ruleForm.name
          } else {
            params.name = ''
          }
          if (this.ruleForm.id) {
            params.id = this.ruleForm.id
          }
          this.$http.axiosPost(this.$api.stuStoreSettingsAdd, params, (res) => {
            if (res.code === 200) {
              this.$message.success(res.msg)
              this.$emit('update:currentIndex', 3)
            } else {
              this.$message.warning(res.msg)
            }
          }, (err) => {
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.big-title {
  font-size: 16px;
  margin: 35px 0 26px;
}
.message {
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  margin-top: 4px;
}
.btn-box {
  text-align: right;
  padding: 10px 74px;
  border-top: 1px solid #DCDCDC;
  margin: 26px -74px 0 -74px;
}
</style>