<template>
  <div>
    <div class="big-title">
      店铺名称
      <span style="font-size: 12px; color: #666">请按命名规范进行命名，若店铺类型不是旗舰店，则店铺名称不可为xxx旗舰店，点击查看<span class="blue">店铺命名详细规则</span></span>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm" label-suffix=":">
      <el-form-item label="店铺类型" prop="store_type">
        <el-radio-group v-model="ruleForm.store_type" style="width: 380px" @change="toggleStoreType">
          <el-radio :label="0">旗舰店</el-radio>
          <el-radio :label="1">专营店</el-radio>
          <el-radio :label="2">专卖店</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="主营类目" prop="good_category_id">
        <el-cascader :props="{ label: 'name', value: 'id' }"
                     filterable
                     placeholder="请选择主营类目" style="width: 380px" v-model="ruleForm.good_category_id" :options="categoryList"></el-cascader>
      </el-form-item>
      <el-form-item label="专营店命名规范">
        公司简称+类目名+专营店
      </el-form-item>
      <el-form-item label="公司简称" prop="company_abbreviation">
        <el-input v-model.trim="ruleForm.company_abbreviation" placeholder="请输入公司简称" clearable style="width: 380px"></el-input>
        <div class="message">如：北京世纪贸易有限公司简称“世贸”</div>
      </el-form-item>
      <el-form-item label="经营类目描述" prop="category_description">
        <el-input v-model.trim="ruleForm.category_description" placeholder="请输入经营类目描述" clearable style="width: 380px"></el-input>
        <div class="message">如：化妆品、服饰、男鞋等</div>
      </el-form-item>
      <el-form-item label="店铺后缀" prop="shop_suffix">
        <el-select v-model="ruleForm.shop_suffix" placeholder="请选择" style="width: 380px">
          <template v-for="(item, index) in shopSuffixList">
            <el-option v-if="ruleForm.store_type === item.type" :label="item.label" :value="item.value" :key="`shopSuffixList_${item.value}`"></el-option>
          </template>
        </el-select>
        <div class="message">请选择您期望的店铺后缀名</div>
      </el-form-item>
      <el-form-item label="期望店铺名称" required>
        {{ruleForm.company_abbreviation}}{{ruleForm.category_description}}{{ruleForm.shop_suffix === 0 ? '专营店' : ruleForm.shop_suffix === 1 ? '专卖店' : ruleForm.shop_suffix === 2 ? '旗舰店' : ruleForm.shop_suffix === 3 ? '官方旗舰店' : ''}}
        <span v-if="ruleForm.shop_suffix === null">&nbsp;</span>
        <div class="message">仅作为参考，最终以审核通过的店铺名称为准</div>
      </el-form-item>

    </el-form>

    <div class="btn-box">
      <el-button @click="$emit('update:currentIndex', 5)">上一步</el-button>
      <el-button class="btn-red" @click="submitForm('ruleForm')">下一步，提交审核</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    ruleForm: {
      type: Object,
      default: () => {}
    },
    checkList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      rules: {
        store_type: [
          { required: true, message: '请选择店铺类型', trigger: 'change' },
        ],
        good_category_id: [
          { required: true, message: '请选择主营类目', trigger: 'change' },
        ],
        company_abbreviation: [
          { required: true, message: '请输入公司简称', trigger: 'blur' },
        ],
        shop_suffix: [
          { required: true, message: '请选择店铺后缀', trigger: 'change' },
        ],
      },
      categoryList: [],
      shopSuffixList: [
        {
          value: 0,
          label: '专营店',
          type: 1
        },
        {
          value: 1,
          label: '专卖店',
          type: 2
        },
        {
          value: 2,
          label: '旗舰店',
          type: 0
        },
        {
          value: 3,
          label: '官方旗舰店',
          type: 0
        },
      ],
    }
  },
  created() {
    this.getCategoryList()
    if (this.$route.query.isAnswer === '1') {
      this.getInfo()
    }
  },
  methods: {
    getInfo() {
      this.$http.axiosGet(this.$api.stuStoreSettingsStepFiveInfo, (res) => {
        if (res.code === 200) {
          if (Object.prototype.toString.call(res.data) === '[object Object]') {
            this.$emit('update:ruleForm', {...this.ruleForm, ...res.data})
          }
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            store_type: this.ruleForm.store_type,
            good_category_id: this.ruleForm.good_category_id.at(-1),
            company_abbreviation: this.ruleForm.company_abbreviation,
            category_description: this.ruleForm.category_description,
            shop_suffix: this.ruleForm.shop_suffix,
          }
          this.$http.axiosPost(this.$api.stuStoreSettingsAddStepFive, params, (res) => {
            if (res.code === 200) {
              this.$message.success(res.msg)
              this.$emit('update:currentIndex', 7)
            } else {
              this.$message.warning(res.msg)
            }
          }, (err) => {
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    toggleStoreType(val) {
      this.ruleForm.shop_suffix = null
    },
    getCategoryList() {
      this.$http.axiosGet(this.$api.storeSettingsGetCategory, (res) => {
        if (res.code === 200) {
          this.categoryList = res.data
        } else {
          this.$message.warning(res.msg)
        }
      }, (err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.big-title {
  font-size: 16px;
  margin: 35px 0 26px;
}
.message {
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  margin-top: 4px;
}
.btn-box {
  text-align: right;
  padding: 10px 74px;
  border-top: 1px solid #DCDCDC;
  margin: 26px -74px 0 -74px;
}
.blue {
  color: #2461EF;
}
</style>