<template>
  <div>
    <div class="prompt-info">
      <div>1.信息提交前，请务必先了解<span class="blue">开发平台招商资质标准细则</span>；</div>
      <div>2.公司信息需要项较多，建议先查看<span class="blue">公司信息注意事项</span>再进行填写；</div>
      <div>3.公司信息请严格按照相关证件信息进行确认填写；</div>
      <div>4.以下所需要上传电子版资质仅支持jpg、gif、png格式的图片，大小不超过1M，且必须加盖企业彩色公章。</div>
    </div>
    <div class="big-title">税务登记证</div>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="190px" class="demo-ruleForm" label-suffix=":">
      <el-form-item label="纳税人类型" prop="taxpayers_type">
        <el-select v-model="ruleForm.taxpayers_type" placeholder="请选择纳税人类型" style="width: 380px">
          <el-option label="一般纳税人" :value="0"></el-option>
          <el-option label="小规模纳税人" :value="1"></el-option>
          <el-option label="非增值税纳税人" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="纳税人识别号" prop="taxpayers_identity_num">
        <el-input v-model.trim="ruleForm.taxpayers_identity_num" placeholder="15位数字" clearable style="width: 380px" :maxlength="15"></el-input>
        <div class="message">三证合一的请填写社会信用代码，一般纳税人税码为13%，小规模税码为3%</div>
      </el-form-item>
      <el-form-item label="纳税类型税码" prop="yax_type">
        <el-select v-model="ruleForm.yax_type" placeholder="请选择纳税类型税码" style="width: 380px">
          <el-option v-for="(item, index) in payTaxesList" :label="item.label" :value="item.value" :key="`payTaxesList_${item.value}`"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="税务登记电子版" prop="tax_pdf_src">
        <div class="title-form">
          <div style="line-height: 40px">请同时上传国税、地税的税务登记证，两者缺一不可，复印件加盖公司红章。<span class="blue">【国地税合一示例】【国地税非合一示例】</span></div>
          如贵司所在地区已推行“三证合一”此处请上传营业执照副本电子版。
        </div>
        <el-upload class="logo-uploader"
                   :action="uploadCover"
                   :headers="headersParams"
                   name="img"
                   :on-change="(file, fileList) => handleChange(file, fileList, 1)"
                   accept=".jpg, .gif, .png, .jpeg"
                   :show-file-list="false"
                   :on-success="(res, file, fileList) => handleCoverSuccess(res, file, fileList, 1)"
                   :before-upload="(file) => beforeCoverUpload(file, 1)">
          <img v-if="ruleForm.tax_pdf_src" :src="ruleForm.tax_pdf_src" class="logo-cover">
          <i v-else class="el-icon-plus logo-uploader-icon"></i>
        </el-upload>
        <div class="message">图片尺寸请确保800px*800px以上，文件大小在5MB以内，支持jpg、gif、png格式，最多可上传1张</div>
      </el-form-item>
      <el-form-item label="一般纳税人资格证电子版" prop="tax_e_src">
        <div class="title-form">
          <div style="line-height: 40px">三证合一地区请上传税务局网站上一般纳税人截图，复印件请加盖开店公司红章</div>
        </div>
        <el-upload class="logo-uploader"
                   :action="uploadCover"
                   :headers="headersParams"
                   name="img"
                   :on-change="(file, fileList) => handleChange(file, fileList, 2)"
                   accept=".jpg, .gif, .png, .jpeg"
                   :show-file-list="false"
                   :on-success="(res, file, fileList) => handleCoverSuccess(res, file, fileList, 2)"
                   :before-upload="(file) => beforeCoverUpload(file, 2)">
          <img v-if="ruleForm.tax_e_src" :src="ruleForm.tax_e_src" class="logo-cover">
          <i v-else class="el-icon-plus logo-uploader-icon"></i>
        </el-upload>
        <div class="message">图片尺寸请确保800px*800px以上，文件大小在1MB以内，支持jpg、gif、png格式，最多可上传1张</div>
      </el-form-item>

      <div class="bar"></div>

      <div class="big-title">银行账号信息</div>
      <el-form-item label="银行开户名">
        银行开户名
      </el-form-item>
      <el-form-item label="对公结算银行账号" prop="corporate_settlement_bank_account">
        <el-input v-model.trim="ruleForm.corporate_settlement_bank_account" placeholder="请输入对公结算银行账号" clearable style="width: 380px"></el-input>
        <div class="message">用于平台与开店公司间账务结算</div>
      </el-form-item>
      <el-form-item label="支行联行号" prop="branch_bank_account">
        <el-input v-model.trim="ruleForm.branch_bank_account" placeholder="请输入支行联行号" clearable style="width: 380px"></el-input>
        <div class="message">请咨询开户行客服获取联行号，通过联行号进行查询选择，若无区支行，可选择上一级市/生机支行</div>
      </el-form-item>
      <el-form-item label="支行名称" prop="branch_bank_name">
        <el-input v-model.trim="ruleForm.branch_bank_name" placeholder="请输入支行名称" clearable style="width: 380px"></el-input>
      </el-form-item>
      <el-form-item label="开户银行支行所在地" prop="open_account_bank">
        <el-cascader :props="{ label: 'value', value: 'code' }"
                     filterable
                     placeholder="请选择开户银行支行所在地" style="width: 380px" v-model="ruleForm.open_account_bank" :options="addressOptions"></el-cascader>
      </el-form-item>
    </el-form>

    <div class="btn-box">
      <el-button @click="$emit('update:currentIndex', 3)">上一步</el-button>
      <el-button class="btn-red" @click="submitForm('ruleForm')">下一步，完善店铺信息</el-button>
    </div>
  </div>
</template>

<script>
import {addressList} from "../../../../utils/address";
import {validateBankConnectionNumber, validateBankNumber, validateTaxpayerNumber} from "../../../admin/storeRegister/component/validator";

export default {
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    ruleForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // S 上传图片需要
      uploadCover: this.$api.stuStoreSettingsUploadImg,
      headersParams: {
        Authorization: localStorage.getItem('token')
      },
      rules: {
        taxpayers_type: [
          { required: true, message: '请选择纳税人类型', trigger: 'change' },
        ],
        taxpayers_identity_num: [
          { required: true, validator: validateTaxpayerNumber, trigger: ['blur', 'change'] }
        ],
        yax_type: [
          { required: true, message: '请选择纳税类型', trigger: 'change' },
        ],
        tax_pdf_src: [
          { required: true, message: '请上传税务登记电子版', trigger: 'blur' },
        ],
        tax_e_src: [
          { required: true, message: '请上传一般纳税人资格证电子版', trigger: 'blur' },
        ],
        corporate_settlement_bank_account: [
          { required: true, validator: validateBankNumber, trigger: ['blur', 'change'] }
        ],
        branch_bank_account: [
          { required: true, validator: validateBankConnectionNumber, trigger: ['blur', 'change'] }
        ],
        branch_bank_name: [
          { required: true, message: '请输入支行名称', trigger: 'blur' },
        ],
        open_account_bank: [
          { required: true, message: '请选择开户银行支行所在地', trigger: 'change' },
        ],
      },
      // 营业执照所在地
      addressOptions: addressList,
      payTaxesList: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 1,
          label: '1%',
        },
        {
          value: 2,
          label: '3%',
        },
        {
          value: 3,
          label: '6%',
        },
        {
          value: 4,
          label: '7%',
        },
        {
          value: 5,
          label: '9%',
        },
        {
          value: 6,
          label: '10%',
        },
        {
          value: 7,
          label: '13%',
        },
        {
          value: 8,
          label: '图书9%免税',
        },
      ],
    }
  },
  created() {
    if (this.$route.query.isAnswer === '1') {
      this.getInfo()
    }
  },
  methods: {
    getInfo() {
      this.$http.axiosGet(this.$api.stuStoreSettingsStepThreeInfo, (res) => {
        if (res.code === 200) {
          if (Object.prototype.toString.call(res.data) === '[object Object]') {
            let open_account_bank = [res.data.open_account_bank_province, res.data.open_account_bank_cit, res.data.open_account_bank_area]
            this.$emit('update:ruleForm', {...this.ruleForm, ...res.data, open_account_bank})
          }
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            taxpayers_type: this.ruleForm.taxpayers_type,
            taxpayers_identity_num: this.ruleForm.taxpayers_identity_num,
            yax_type: this.ruleForm.yax_type,
            tax_pdf_src: this.ruleForm.tax_pdf_src,
            tax_e_src: this.ruleForm.tax_e_src,
            corporate_settlement_bank_account: this.ruleForm.corporate_settlement_bank_account,
            branch_bank_account: this.ruleForm.branch_bank_account,
            branch_bank_name: this.ruleForm.branch_bank_name,
            open_account_bank_province: this.ruleForm.open_account_bank[0],
            open_account_bank_cit: this.ruleForm.open_account_bank[1],
            open_account_bank_area: this.ruleForm.open_account_bank[2],
          }
          if (this.ruleForm.id) {
            params.id = this.ruleForm.id
          }
          this.$http.axiosPost(this.$api.stuStoreSettingsAddStepThree, params, (res) => {
            if (res.code === 200) {
              this.$message.success(res.msg)
              this.$emit('update:currentIndex', 5)
            } else {
              this.$message.warning(res.msg)
            }
          }, (err) => {
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // start 上传图片
    beforeCoverUpload(file, type) {
      const isLt5M = file.size / 1024 / 1024 <= 5;
      const isLt1M = file.size / 1024 / 1024 <= 1;
      if (type === 1 && !isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      if (type === 2 && !isLt1M) {
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      const isSize = new Promise((resolve, reject) => {
        const width = 800
        const height = 800
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = () => {
          const valid = img.width >= width && img.height >= height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        this.$message.warning('图片尺寸请确保800px*800px以上')
        return Promise.reject()
      })
      if (type === 1) {
        return isLt5M && isSize
      } else {
        return isLt1M && isSize
      }
    },
    handleCoverSuccess(res, file, fileList, type) {
      if (res.code === 200) {
        if (type === 1) {
          this.ruleForm.tax_pdf_src = res.data; //无域名
        } else {
          this.ruleForm.tax_e_src = res.data; //无域名
        }
        this.$message.success('上传成功')
      } else {
        this.$message.error('图片上传失败，请稍后再试~')
      }
    },
    handleChange(uploadFile, uploadFiles, type) {
      if (type === 1 && this.ruleForm.tax_pdf_src) {
        this.$refs.ruleForm.validateField('tax_pdf_src')
      }
      if (type === 2 && this.ruleForm.tax_e_src) {
        this.$refs.ruleForm.validateField('tax_e_src')
      }
    }
    // end 上传图片
  }
}
</script>

<style scoped lang="scss">
.big-title {
  font-size: 16px;
  margin: 35px 0 26px;
}
.message {
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  margin-top: 4px;
}
.title-form {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}
.btn-box {
  text-align: right;
  padding: 10px 74px;
  border-top: 1px solid #DCDCDC;
  margin: 26px -74px 0 -74px;
}

:deep(.logo-uploader) {
  width: 120px;
  height: 120px;
  background: #F7F7F7;
  .el-upload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo-cover {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
  .logo-uploader-icon {
    color: #999;
    font-size: 24px;
  }
}
.blue {
  color: #2461EF;
}
.prompt-info {
  background: #FFFDF0;
  border: 1px solid #FFC8A0;
  padding: 12px 46px;
  line-height: 30px;
}
.bar {
  border: 1px dashed #D7DDE4;
}
</style>